import { InjectionToken } from '@angular/core'

export type QuillToolbarConfig = Array<Array<
  string | {
    indent?: string
    list?: string
    direction?: string
    header?: number | Array<boolean | number>
    color?: string[]
    background?: string[]
    align?: string[]
    script?: string
    font?: string[]
    size?: Array<boolean | string>
  }
>>

export interface QuillModules {
  [key: string]: any
  clipboard?: {
    matchers?: any[]
    matchVisual?: boolean
  } |Â boolean
  history?: {
    delay?: number
    maxStack?: number
    userOnly?: boolean
  } |Â boolean
  keyboard?: {
    bindings?: any
  } | boolean
  syntax?: boolean
  toolbar?: QuillToolbarConfig |Â string |Â {
    container?: string | string[] | QuillToolbarConfig
    handlers?: {
      [key: string]: any
    }
  } |Â boolean
}

export type QuillFormat = 'object' | 'json' | 'html' |Â 'text'

export interface QuillConfig {
  bounds?: HTMLElement |Â string
  debug?: 'error' | 'warn' | 'log' |Â false
  format?: QuillFormat
  formats?: any
  modules?: QuillModules
  placeholder?: string
  readOnly?: boolean
  scrollingContainer?: HTMLElement |Â string |Â null
  theme?: string
  // Custom Config to track all changes or only changes by 'user'
  trackChanges?: 'user' | 'all'
}

export const QUILL_CONFIG_TOKEN = new InjectionToken<QuillConfig>('config')
